import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { RechazarOrdenPageRoutingModule } from './rechazar-orden-routing.module';

import { RechazarOrdenPage } from './rechazar-orden.page';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SharedModule,
    RechazarOrdenPageRoutingModule
  ],
  declarations: [RechazarOrdenPage]
})
export class RechazarOrdenPageModule {}
