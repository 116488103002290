import { Component, Injectable } from '@angular/core';
import { Platform, ActionSheetController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
//import { OneSignal } from '@ionic-native/onesignal/ngx';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { UtilService } from './services/util.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
@Injectable()
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    //private oneSignal: OneSignal,
    private translate: TranslateService,
    private nativeAudio: NativeAudio,
    private actionSheetController: ActionSheetController,
    private util: UtilService
  ) {

    const lng = 'spanish';
    console.log('idioma', lng);
        if (!lng || lng === null) {
      localStorage.setItem('language', 'spanish');
    }
    this.translate.use(localStorage.getItem('language'));
    this.initializeApp();
  }
  

  async presentActionSheet() {
    const actionSheet = await this.actionSheetController.create({
      header: this.util.translate('New Notification'),
      mode: 'md',
      buttons: [{
        text: this.util.translate('OK'),
        icon: 'volume-mute',
        handler: () => {
          console.log('Delete clicked');
          this.nativeAudio.stop('audio').then(() => console.log('done'), () => console.log('error'));
        }
      }, {
        text: this.util.translate('Cancel'),
        icon: 'close',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
          this.nativeAudio.stop('audio').then(() => console.log('done'), () => console.log('error'));
        }
      }]
    });

    await actionSheet.present();
  }
  initializeApp() {
    
    this.platform.ready().then(() => {
      var OneSignalBrowser = window["OneSignal"] || [];
        OneSignalBrowser.push(["init", {
          appId: environment.onesignal.appId,
          persistNotification: true,
          autoRegister: true,
          safari_web_id: "web.onesignal.auto.5f83a190-684a-4c4c-a875-2ee8aa7bf929",
          notifyButton: {
            enable: true,
          },
          
        }]);
        console.log('OneSignal Initialized');
        OneSignalBrowser.push(function () {
          console.log('Register For Push');
          OneSignalBrowser.push(["registerForPushNotifications"])
        });
        OneSignalBrowser.push(function () {
          // Occurs when the user's subscription changes to a new value.
          OneSignalBrowser.on('subscriptionChange', function (isSubscribed) {
            console.log("The user's subscription state is now:", isSubscribed);
            OneSignalBrowser.getUserId().then(function (userId) {
              console.log("User ID is", userId);
              localStorage.setItem('fcm', userId);
            });
          });
        });
       /* OneSignalBrowser.push(function() {
          OneSignalBrowser.getUserId().then(function (userId) {
            console.log("User ID is", userId);
            localStorage.setItem('fcm', userId);
          });
        });*/
      
      this.nativeAudio.preloadSimple('audio', 'assets/alert.mp3').then((data: any) => {
        console.log('dupletx', data);
      }, error => {
        console.log(error);
      }).catch(error => {
        console.log(error);
      });
      this.statusBar.backgroundColorByHexString('#ff384c');
      this.splashScreen.hide();
    });
  }
}
