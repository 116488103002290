import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';


@Component({
  selector: 'app-select-drivers',
  templateUrl: './select-drivers.page.html',
  styleUrls: ['./select-drivers.page.scss'],
})
export class SelectDriversPage implements OnInit {
  drivers: any[] = [];
  selectedDriver: any = '';
  tiempoest: any;
  todo: any[] = [];
  constructor(
    private navParam: NavParams,
    private modalController: ModalController
  ) {
    this.drivers = this.navParam.get('item');
    console.log('drivers->', this.drivers);
    if (this.drivers.length && this.drivers.length > 0) {
      this.selectedDriver = this.drivers[0].id;
    }
  }

  ngOnInit() {
  }
  select() {
    console.log('selected-driver-select-driver-page',this.selectedDriver);
    console.log('tiempo',this.tiempoest);
    let driver = this.drivers.filter(x => x.id === this.selectedDriver);
    this.todo.push(driver);
    this.todo.push(this.tiempoest);
    this.modalController.dismiss(this.todo, 'selected');
    console.log('todo',this.todo);
  }
  close() {
    this.modalController.dismiss([], 'close');
  }
}
