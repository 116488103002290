// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBJ0AUXbeH6nlozg059kkW0Av1iy6OCpvg",
  authDomain: "toc-userapp.firebaseapp.com",
  databaseURL: "https://toc-userapp.firebaseio.com",
  projectId: "toc-userapp",
  storageBucket: "toc-userapp.appspot.com",
  messagingSenderId: "801759085113",
  appId: "1:801759085113:web:5da0d151175fb51f9703e8",
  measurementId: "G-XLWEV20XT1"
  },
  onesignal: {
    appId: 'ca3065d2-de7d-46b5-99d9-bcaaf8174555',
    googleProjectNumber: '801759085113',
    restKey: 'ZjJiNTgyYWEtYWY4Zi00ZjdiLTgxZWMtMDliMjA5OTc0ZWQ5'
  },
  stripe: {
    sk: ''
  },
  paypal: {
    sandbox: '',
    production: 'YOUR_PRODUCTION_CLIENT_ID'
  },
  general: {
    symbol: '$',
    code: 'MXN'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
