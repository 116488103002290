import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';


@Component({
  selector: 'app-rechazar-orden',
  templateUrl: './rechazar-orden.page.html',
  styleUrls: ['./rechazar-orden.page.scss'],
})
export class RechazarOrdenPage implements OnInit {
  porquerechaza: any;
  todo: any;
  constructor(
    private navParam: NavParams,
    private modalController: ModalController
  ) {
  }

  ngOnInit() {
  }
  rechazarorden() {
    console.log('porquerechaza',this.porquerechaza);
    this.todo = this.porquerechaza;
    this.modalController.dismiss(this.todo, 'rechazada');
    console.log('todo',this.todo);
  }
  close() {
    this.modalController.dismiss([], 'close');
  }
}
